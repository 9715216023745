import React from "react";
import { graphql } from "gatsby";
import { Link } from "react-scroll";
import {get} from "lodash";
import HubspotForm from "react-hubspot-form";
import BlogFeed from "components/blog/latest-blog-feed";
import TestimonialCard from "components/testimonials/testimonial-card";
import DefaultLayout from "components/layout/default";
import FeatureItem from "components/blog-item";
import layoutStyle from "styles/layout.module.css";
import radiusTopCapImage from "images/product-radius-top-cap.svg";
import radiusBottomCapImage from "images/product-radius-bottom-cap.svg";
import radiusCapBlackTopSmImage from "images/radius-cap-black-top-sm.svg";
import radiusCapBlackBottomSmImage from "images/radius-cap-black-bottom-sm.svg";
import penTestImage1 from "images/penetration-testing-image-1.svg";
import penTestImage2 from "images/penetration-testing-image-2.svg";
import sponsorsImg from "images/PT-Temp-sponsors.png";
import wardenImage from "images/logo-solutions-penetration-test.png";
import iconImage1 from "images/PT-Icon1.svg";
import iconImage2 from "images/PT-Icon2.svg";
import iconImage3 from "images/PT-Icon3.svg";
import iconImage4 from "images/PT-Icon4.svg";
import iconImage5 from "images/PT-Icon5.svg";
import iconImage6 from "images/PT-Icon6.svg";
import sparrowLogo from "images/logos/sparrow.png";
import Img from "components/image";
import { IMAGE_BACKGROUND_PROPS } from "constants/index";
import style from "styles/pages/products.module.css";
import styles from "styles/pages/solutions.module.css";
import moneysmart from "images/logos/moneysmart.svg";
import ninjavan from "images/logos/ninjavan.svg";
import bukalapak from "images/logos/bukalapak.jpg";
import tiket from "images/logos/tiket.com.svg";
import jirnexu from "images/logos/Jirnexu.png";
import fundingLogo from "images/logos/funding-societies.png";
import gojek from "images/logos/gojek.png";
import propertyguru from "images/logos/propertyguru.svg";
import fave from "images/logos/fave.png";

const HUBSPOT_FORM_ID = "d5e309a0-a247-4a4f-990c-c03bdfdcd6b6";
import { selectBlog, fetchBlogs } from "services/blogs";

function PenetrationTestPage(props) {
  const [hubspotFormReady, setHubspotFormReady] = React.useState(false);
  const [hubspotFormSubmitted, setHubspotFormSubmitted] = React.useState(false);
  let [mainImageLoaded, setMainImageLoaded] = React.useState(false);
  let mainImageRef = React.useRef();
  const [blogs, setBlogs] = React.useState(null);

  React.useEffect(() => {
    fetchBlogs().then(res => setBlogs(res.items));
  }, []);

  React.useEffect(() => {
    setMainImageLoaded(mainImageRef.current.complete);
  }, [mainImageRef]);

  function handleMainImageLoad() {
    setMainImageLoaded(true);
  }

  function handleHubspotFormReady(event) {
    setHubspotFormReady(true);
  }

  function handleHubspotFormSubmit(event) {
    if (event.data.type === "hsFormCallback") {
      if (event.data.eventName === "onFormSubmit") {
        setHubspotFormSubmitted(true);
      }
    }
  }

  function renderVerticalLine() {
    let height = "0%";
    if (mainImageLoaded) height = "64vh";
    return (
      <div
        className={`${style.verticalLine} absolute top-0 w-px bg-white hidden md:block`}
        style={{ height }}
      />
    );
  }

  function renderContactFormHint() {
    let hint;
    const privacyLink = (
      <a
        href="/legal/privacy"
        className="underline text-orange"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Notice
      </a>
    );
    if (hubspotFormReady === true && hubspotFormSubmitted === false) {
      hint = (
        <p className="text-black text-sm my-4">
          By clicking “Send”, you acknowledge that you have read, and consent to
          collection, use and disclosure of your personal data for the purposes
          set out in our {privacyLink}.
        </p>
      );
    }
    return hint;
  }

  function renderSection1() {
    return (
      <section className="relative min-h-screen">
        <div className="container mx-auto px-8 pt-4 pb-16 md:py-32 z-10 relative">
          <div className="text-center px-8 md:px-0">
            <img
              ref={mainImageRef}
              className="inline-block pointer-events-none"
              src={wardenImage}
              width={340}
              alt=""
              onLoad={handleMainImageLoad}
            />
          </div>
          <div className="relative flex">
            {renderVerticalLine()}
            <div className="md:w-1/2 flex-none" />
            <div className="ml-0 md:ml-16">
              <h3 className="font-bold text-4xl lg:text-6xl leading-tight mt-4">
                Penetration Testing
              </h3>
              <p>
                Turn your critical vulnerabilities into business opportunity.
              </p>
            </div>
          </div>
        </div>
        <Img
          {...IMAGE_BACKGROUND_PROPS}
          objectFit="contain"
          alt="Background"
          fluid={props.data.backgroundImage.childImageSharp.fluid}
        />
      </section>
    );
  }

  function renderSection2() {
    const subHeaderText = (
      <h3 className="font-serif italic text-2xl sm:text-4xl lg:text-6xl text-center leading-tight mt-4 whitespace-pre-wrap">
        Assess Your Security Posture Using Horangi’s Holistic Methodology
      </h3>
    );
    return (
      <section>
        <div className="container mx-auto px-8 pt-32 lg:py-0 relative text-black">
          {subHeaderText}
        </div>
      </section>
    );
  }

  function renderSection3() {
    return (
      <section className="relative bg-white text-black flex justify-center text-black bg-white">
        <div className="flex flex-wrap container pt-40">
          <div key={`item-${1}`} className="md:w-1/3">
            <div className="p-10">
              <img
                src={iconImage1}
                alt="Icon"
                width={48}
                className="mb-6 pointer-events-none"
              />
              <h2 className="font-bold text-lg leading-tight">
                HIGHLY SKILLED PENTESTERS
              </h2>
              <p className="mt-2">
                Horangi’s certified consultants are adept at cybercrime
                investigation, anti money laundering, and military-grade
                incident response.
              </p>
            </div>
          </div>
          <div key={`item-${2}`} className="md:w-1/3">
            <div className="p-10">
              <img
                src={iconImage2}
                alt="Icon"
                width={48}
                className="mb-6 pointer-events-none"
              />
              <h2 className="font-bold text-lg leading-tight">
                BESPOKE CONSULTATION
              </h2>
              <p className="mt-2">
                Understand the risk and business impact of each identified
                vulnerability to better prioritize remediation.
              </p>
            </div>
          </div>
          <div key={`item-${3}`} className="md:w-1/3">
            <div className="p-10">
              <img
                src={iconImage3}
                alt="Icon"
                width={48}
                className="mb-6 pointer-events-none"
              />
              <h2 className="font-bold text-lg leading-tight">
                CUSTOMIZED REPORT
              </h2>
              <p className="mt-2">
                Vulnerability data in the pentest report is contextualized for
                your organization.
              </p>
            </div>
          </div>
          <div key={`item-${4}`} className="md:w-1/3">
            <div className="p-10">
              <img
                src={iconImage4}
                alt="Icon"
                width={48}
                className="mb-6 pointer-events-none"
              />
              <h2 className="font-bold text-lg leading-tight">
                BENCHMARK YOUR SECURITY MATURITY TO THE INDUSTRY
              </h2>
              <p className="mt-2">
                Horangi’s evaluation is based on your organization’s security
                responsiveness and competence.
              </p>
            </div>
          </div>
          <div key={`item-${5}`} className="md:w-1/3">
            <div className="p-10">
              <img
                src={iconImage5}
                alt="Icon"
                width={48}
                className="mb-6 pointer-events-none"
              />
              <h2 className="font-bold text-lg leading-tight">
                EXTENSIVE SPECTRUM OF PENTESTS
              </h2>
              <p className="mt-2">
                We conduct pentests on web and mobile applications, network,
                cloud environments, web servers, and Internet-facing systems.
              </p>
            </div>
          </div>
          <div key={`item-${6}`} className="md:w-1/3">
            <div className="p-10">
              <img
                src={iconImage6}
                alt="Icon"
                width={48}
                className="mb-6 pointer-events-none"
              />
              <h2 className="font-bold text-lg leading-tight">
                BASED ON RECOGNIZED PENTESTING STANDARDS
              </h2>
              <p className="mt-2">
                Get tested on the most updated attack vectors from OWASP Top 10,
                CREST, and GIAC.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }

  function renderSection4() {
    return (
      <section className="relative bg-white p-0 md:p-64 -mb-64">
        <img
          src={sponsorsImg}
          className="mx-auto m-64"
          alt=""
          style={{ maxHeight: 230 }}
        />
      </section>
    );
  }

  function renderSection5() {
    return (
      <section
        id="testimonial-card"
        className="flex justify-content items-center pt-40 md:pt-0 pb-32 md:pb-0"
        style={{position: "relative", zIndex: 1000}}
      >
        <TestimonialCard
          logo={sparrowLogo}
          quote="Sparrow is committed to providing the most secure platform to enable everyone and anyone to trade confidently. We recognize the world-class standard of Horangi’s cybersecurity consultants and are proud to have passed their stringent security assessments on Sparrow’s web applications."
          author="KENNETH YEO, CEO"
        />
      </section>
    );
  }

  function renderSection6() {
    return (
      <section
        id="company-logos"
        className="mt-20 mb-64"
        style={{ position: "relative", zIndex: 1000 }}
      >
        <p className="text-black text-center font-bold text-xl pl-10 md:pl-20 pr-10 md:pr-20 mb-10">
          A WORLD-CLASS SERVICE TRUSTED BY ASIA&apos;S INDUSTRY LEADERS
        </p>
        <div className="partner logo placeholder mx-auto mt-4 text-center">
          <img className={styles.clientLogo} style={{height: 20}} src={moneysmart} alt="PropertyGuru"/>
          <img className={styles.clientLogo} src={gojek} alt="Tiket.com"
            />
          <img className={styles.clientLogo} src={propertyguru} alt="Go Jek"
            style={{height: 50}}/>
          <img className={styles.clientLogo} src={ninjavan} alt="MoneySmart"
            style={{height: 40}}/>
    
        </div>
        <div className="partner logo placeholder mx-auto mt-4 text-center">
          <img className={styles.clientLogo} src={bukalapak} style={{height: 40}} alt="PropertyGuru"/>
          <img className={styles.clientLogo} src={tiket} alt="Tiket.com"
            style={{height: 40}}/>
          <img className={styles.clientLogo} src={jirnexu} alt="Go Jek"
            style={{height: 40}}/>
          <img className={styles.clientLogo} src={fave} alt="MoneySmart"
            style={{height: 40}}/>
         <img className={styles.clientLogo} src={fundingLogo} style={{height:40}} alt="MoneySmart"/>
        </div>
{/*    
        <div className="w-full flex justify-content mt-10">
          <div className="w-5/6 flex justify-content items-center mx-auto">
            <div className="w-1/4 flex items-center">
              <img className="mx-auto h-5 w-48" src={moneysmart} alt="" />
            </div>
            <div className="w-1/4 flex items-center">
              <img className="h-10 mx-auto" src={gojek} alt="" />
            </div>
            <div className="w-1/4 flex items-center">
              <img className="h-10 mx-auto" src={propertyguru} alt="" />
            </div>
            <div className="w-1/4 flex items-center">
              <img className="h-10 mx-auto" src={ninjavan} alt="" />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-content mt-5">
          <div className="w-full flex justify-content items-center mx-auto">
            <div className="w-1/5 flex items-center">
              <img src={bukalapak} alt="" className="mx-auto h-10" />
            </div>
            <div className="w-1/5">
              <img src={tiket} alt="" className="mx-auto" />
            </div>
            <div className="w-1/5 flex items-center">
              <img src={jirnexu} alt="" className="mx-auto h-10" />
            </div>
            <div className="w-1/5 flex items-center">
              <img className="mx-auto h-10" src={fave} alt="" />
            </div>
            <div className="w-1/5 flex items-center">
              <img className="mx-auto h-10" src={fundingLogo} alt="" />
            </div>
          </div>
        </div> */}
      </section>
    );
  }

  function renderSection7() {
    const blog = selectBlog(blogs, "Pentesting Methodology 101");
    return (
      <section className="relative text-white sm:mb-160">
        <img
          style={{marginBottom: -1}}
          src={radiusCapBlackTopSmImage}
          alt="Background"
          className="w-full z-1 pointer-events-none relative"
        />
        <div className="bg-black relative z-10 pb-16 sm:pb-0 mt-0 sm:-mt-px">
          <div className="container mx-auto px-8 pt-16 md:pt-32 z-10 relative">
            <div className="sm:flex">
              <div className="sm:w-1/2 mt-64 md:mt-0">
                <h2 className="font-bold text-xl leading-tight uppercase">
                  GET TESTED BY A DIVERSE TEAM OF WHITE HAT HACKERS
                </h2>
                <h3 className="font-serif italic text-4xl lg:text-6xl leading-tight mt-4 whitespace-pre-wrap">
                  Harden Your Security With Every Horangi Pentest
                </h3>
                <p className="mt-8 mb-10">
                  Horangi pinpoints and qualifies the vulnerabilities in your
                  platform, application, server, or network through every
                  pentest. Leverage the combined expertise of the Horangi team
                  trained in the largest National Security and Law Enforcement
                  agencies globally to get better, actionable security insights
                  for your organization.
                </p>
                <Link
                  activeClass="active"
                  to="contact-form"
                  spy={true}
                  smooth={true}
                  offset={20}
                  duration={800}
                >
                <div className="py-6 -mt-8">
                  <a href="/contact/" className="btn btn-orange inline-block">
                    Contact Us
                  </a>
                </div>
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.computerImage}>
            <img src={penTestImage1} className={styles.pentestImage1} alt="" />
          </div>
          <section id="test" className="flex flex-wrap mt-20">
            <div
            className="w-full xl:w-2/3 p-20"
            >
              <FeatureItem
                image={get(blog, "image")}
                title="ABOUT PENETRATION TESTING"
                description="Pentesting is an authorized simulation of an attack on a system, network, or application to find potential vulnerabilities that can be exploited."
                url="/blog/pentesting-methodology-101"
              />
            </div>
            <div
            className="w-full md:mt-10 xl:w-1/3 p-10 pr-16"
            >
              <div className="flex justify-center" >
                <BlogFeed />
              </div>
            </div>
          </section>

          <div className={styles.hardenSecuritySection}>
            <div className="container mx-auto px-8 pb-48 sm:pb-0 md:pb-20 md:pt-32 z-10 relative mt-64">
              <div className="sm:flex justify-end">
                <div className="sm:w-1/2">
                  <h2 className="font-bold text-xl leading-tight uppercase">
                    BEAT THE TOUGHEST SECURITY STANDARDS
                  </h2>
                  <h3 className="font-serif italic text-4xl lg:text-6xl leading-tight mt-4 whitespace-pre-wrap">
                    Make Security Your New Competitive Advantage
                  </h3>
                  <p className="mt-8 mb-16">
                    Horangi&apos;s methodical approach to pentesting builds
                    long-term secruity resilience around your organization’s
                    most valuable digital assets. Pass the most sophisticated
                    OWASP Top 10 attacks to continuously assure your customers
                    and stakeholders of your commitment to their safety.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <img
              src={penTestImage2}
              alt=""
              className="absolute -mt-48 md:-mt-64"
            />
          </div>
        </div>
        <img
          src={radiusCapBlackBottomSmImage}
          alt="Background"
          className="w-full z-1 pointer-events-none relative bg-white pb-20 md:pb-0 mt-0 sm:-mt-px"
        />
      </section>
    );
  }

  function renderSection9() {
    return (
      <section
      
        id="contact-form"
        className="relative flex flex-col justify-center items-center bg-white text-black -mt-32 lg:-mt-32 mb-128 sm:-mb-48 pb-10 md:pt-20 lg:pt-64"
      >
        <h1 className="font-bold text-md p-5 sm:p-0 text-center sm:-mt-20 sm:mt-0 sm:text-left text-lg">
          SPEAK TO A HORANGI TIGER TODAY
        </h1>
        <h2 className="text-center text-sm p-5 sm:p-0 text-lg mt-0 sm:mt-5 mb-20 sm:mb-64">
          If your organization has a penetration test requirement, <br />
          complete the form below to request a quote from our leading security
          consultants.
        </h2>
        <div className="container mx-auto px-8 py-4 z-10 absolute max-w-4xl bg-white mt-128 sm:mt-96">
          <HubspotForm
            portalId={process.env.GATSBY_HUBSPOT_ID}
            formId={HUBSPOT_FORM_ID}
            onReady={handleHubspotFormReady}
            onSubmit={handleHubspotFormSubmit}
          />
          {renderContactFormHint()}
        </div>
      </section>
    );
  }

  return (
    <DefaultLayout
      location={props.location}
      title="Horangi Penetration Testing"
      description="Assess your security posture using Horangi’s holistic methodology. Turn your critical vulnerabilities into business opportunity."
    >
      <div className={layoutStyle.headerPlaceholder} />
      {renderSection1()}
      <img
        src={radiusTopCapImage}
        alt="Background"
        className="w-full z-1 pointer-events-none"
      />
      <div className="relative z-10 -mt-32 overflow-hidden">
        <div
          className="bg-white absolute w-full"
          style={{ top: "127px", bottom: "127px" }}
        />
        {renderSection2()}
        {renderSection3()}
        {renderSection4()}
        {renderSection5()}
        {renderSection6()}
        {renderSection7()}
      </div>
      {renderSection9()}
      <img
        src={radiusBottomCapImage}
        alt="Background"
        className="w-full z-1 md:pt-48 pointer-events-none"
      />
      <div className="pb-64 md:pb-128 lg:pb-96" />
    </DefaultLayout>
  );
}

export default React.memo(PenetrationTestPage);

export const QUERY = graphql`
  query SolutionsPenetrationTestPage {
    pageContent: file(
      relativePath: { eq: "solution-pages/penetrationtesting.json" }
    ) {
      childProductPagesJson {
        title
        description
      }
    }
    backgroundImage: file(
      relativePath: { eq: "background-products-item.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: PNG, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    featureImage: file(relativePath: { eq: "pentesting-blog-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: PNG, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
