import React from "react";
import { Link } from "gatsby";
import { EXTERNAL_LINK_PROPS } from "constants/index";

const FeatureItem = ({ image, url, title, description }) => {
  if(!image || !url || !title || !description){
    return null;
  }
  return (
    <div className="flex flex-wrap">
    <div className="w-full md:w-1/2 md:mb-20">
      <img className="" src={image.src} alt=""/>
    </div>
    <div
      className={`w-full md:w-1/2 p-6 lg:p-8 bg-horangi-gray h-full my-auto md:-ml-20`}>
      <h4 className="text-sm font-bold uppercase">{title}</h4>
      <p className="mt-4 font-bold">{description}</p>
      <Link
        to={url}
        className="text-orange inline-block mt-4"
        {...EXTERNAL_LINK_PROPS}>
        Read More
      </Link>
    </div>
  </div>
  
  );
};

export default FeatureItem;