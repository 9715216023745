import React from "react";
import { Link } from "gatsby";
import Img from "components/image";
import { useStaticQuery, graphql } from "gatsby";

const LatestBlogFeed = ({ className }) => {
  const { latestBlogs } = useStaticQuery(gql);
  function renderBlogItem() {
    return latestBlogs.edges.map((blog, index) => {
      const { formattedDate, title, featureImage } = blog.node.frontmatter;
      const author = blog.node.fields.author.name;
      return (
        <div key={index} className={className}>
          <Link to={`/blog/${blog.node.fields.slug}`}>
            <div className={`flex m-3 mt-5 cursor-pointer`}>
              <div className="w-1/4 flex items-center">
                <Img
                  fluid={featureImage.childImageSharp.fluid}
                  className="w-20"
                />
              </div>
              <div className="w-3/4 ml-3">
                <p style={{fontWeight: 500}} className="text-sm text-white">{title}</p>
                <p style={{fontWeight: 500}} className="text-xs mt-2 text-gray-400">
                  By {author} - {formattedDate}
                </p>
              </div>
            </div>
          </Link>
        </div>
      );
    });
  }
  return <div className="bg-horangi-gray max-w-lg p-5">{renderBlogItem()}</div>;
};

const gql = graphql`
  {
    latestBlogs: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//content/blog-posts//" } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 4
    ) {
      edges {
        node {
          id
          fields {
            slug
            author {
              name
            }
          }
          frontmatter {
            title
            featureImage {
              childImageSharp {
                fluid(maxWidth: 1280, toFormat: JPG, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            date
            formattedDate: date(formatString: "MMM DD, YYYY")
          }
        }
      }
    }
  }
`;

export default LatestBlogFeed;
