import find from "lodash/find";

export const fetchBlogs = () => {
  return new Promise((resolve, reject) => {
    fetch("/blog-feed.json")
    .then(response => response.json())
    .then(data => resolve(data))
    .catch(error =>  reject(error));
  });
};

export const selectBlog = (blogs, title) => {
  const result = find(blogs, { title: title });
  if (result) {
    return {
      image: result.image.childImageSharp.fluid,
      title: result.title,
      description: result.summary,
      url: result.url
    };
  } else {
    return null;
  }
};
