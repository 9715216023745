import React from "react";
import quotes from "images/orange-quotes.png";
import styles from "styles/pages/customers.module.css";

const TestimonialCard = ({ logo, companyName, quote, author }) => {
  return (
    <div
      id="testimonial-card"
      className="w-4/5 md:w-full max-w-2xl rounded overflow-hidden shadow-lg p-5 sm:p-16 sm:mb-20 mx-auto bg-white"
    >
      <img
        src={quotes}
        alt=""
        style={{ position: "absolute", zIndex: 2000 }}
        className="-mt-16 sm:-mt-32 xxl:-mt-32 -ml-5 sm:-ml-20 h-16 sm:h-24"
      />
      <div className="flex flex-row mt-5 sm:mt-0">
        <img src={logo} alt="" style={{ height: 50 }} />
        <p className="text-xl sm:text-2xl mb-5 mt-2 text-black">
          {companyName}
        </p>
      </div>
      <p className={`italic ${styles.lora_font} sm:text-md mt-5 text-black leading-relaxed`}>
      &quot;{quote}&quot;
      </p>
      <p className="sm:mt-5 text-gray-500 uppercase text-sm font-bold">
        {author}
      </p>
    </div>
  );
};

export default TestimonialCard;
